import React, { useState } from 'react';
import { Form, Input, message, Modal, Radio, RadioChangeEvent, Space, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { useAppNotification } from '../hooks/useAppNotification';
import { createFaultReport } from '../apis/faultReports';
import Text from './Text';
import { convertToApiFile } from '../utils/global';
import { Notice } from './Notice';
import DetailsView from './DetailsView';
import SizeBox from './SizeBox';
import UploadAttachmentButton from './buttons/UploadAttachmentButton';
import { FaultCategories } from '../constants';
import Divider from './Divider';
import ConfirmButton from './buttons/ConfirmButton';
import SecondaryButton from './buttons/SecondaryButton';
import Icon from './Icon';

type FormI = {
  type: string;
  description: string;
  attachments: any[];
  serialCode: string;
};

type Props = {
  data?: any;
  hardwareType?: string;
  setIsModalVisible: (value: boolean) => void;
  isModalVisible: boolean;
  setCondition?: (_: string) => void;
};

const FaultyReportModal: React.FC<Props> = ({
  data,
  hardwareType = 'ecotote',
  setIsModalVisible,
  isModalVisible,
  setCondition,
}) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any[]>([]);
  const [isSelectProductFault, setIsSelectProductFault] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { openNotification } = useAppNotification();

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setFiles([]);
  };

  const { mutate: createMutate, isLoading } = useMutation(createFaultReport, {
    onSuccess: async (successData) => {
      setCondition?.('faulty');
      openNotification({
        type: 'success',
        title: 'Hardware Fault Report Submitted!',
        content: (
          <Text variant="bodyMd">
            Fault report <span className="tw-font-bold">{successData?.code}</span> has been submitted. Hardware Owner
            and Allocatee of <span className="tw-font-bold">{data?.serialCode}</span> have been notified.
          </Text>
        ),
      });
      queryClient.invalidateQueries();
      handleCancel();
    },
    onError: () => {
      message.error('Your action failed. Please try again!');
      handleCancel();
    },
  });

  const onRadioChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    if (value === 'productFault') {
      setIsSelectProductFault(true);
    } else {
      setIsSelectProductFault(false);
    }
  };

  const handleSubmit = (values: FormI) => {
    const newValues: FormI = values;
    if (files && Object.keys(files).length > 0) {
      if (files?.[0]?.data) {
        const file: any = files[0];
        const newFile = convertToApiFile(file);
        newValues.attachments = [newFile];
      }
    }
    newValues.serialCode = data?.serialCode ?? '';
    createMutate(newValues);
  };

  return (
    <div onKeyDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
      <Modal
        open={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
        width={681}
        title={<Text variant="h2">Report Fault</Text>}
        className="fault-report-button-container"
      >
        <Spin spinning={isLoading}>
          <Notice
            type="warning"
            description={
              <span>
                The condition of this hardware will be changed to <span className="tw-text-error-500">Faulty</span> once
                the fault report has been submitted successfully.
              </span>
            }
            closable={false}
          />
          <Form form={form} onFinish={handleSubmit} scrollToFirstError autoComplete="off" layout={'vertical'}>
            <div className="form-container">
              <div className="form-content">
                <Form.Item
                  name="type"
                  label="Fault Type"
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <Radio.Group value={data?.status} onChange={onRadioChange}>
                    <Space direction="vertical" className="tw-w-full">
                      <Radio value={'hardwareFault'}>Hardware Fault</Radio>
                      {hardwareType === 'ecotote' && <Radio value={'productFault'}>Product Fault</Radio>}
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {isSelectProductFault && (
                  <>
                    {data?.ecoTote?.product ? (
                      <DetailsView.Field
                        label="Last Filled Product"
                        value={`${data?.ecoTote?.product.name} - ${data?.ecoTote?.product.sku}`}
                      />
                    ) : (
                      <Text
                        variant="capt1"
                        className="tw-flex tw-items-center tw-flex-wrap tw-text-error-500 tw-gap-1.5"
                      >
                        <Icon name="icon-error_outline" size={12}></Icon>Unable to retrieve Product in this Hardware.
                        Please switch your fault type to Hardware Fault instead.
                      </Text>
                    )}
                    <SizeBox height={16} />
                  </>
                )}
                <Form.Item
                  name="description"
                  label="Fault Description"
                  rules={[{ required: true, message: 'This field is required.' }]}
                >
                  <Input.TextArea placeholder="Type Fault Description" />
                </Form.Item>
                <Form.Item name="attachments" label="Attachments">
                  <UploadAttachmentButton categories={FaultCategories} form={form} setFiles={setFiles} files={files} />
                </Form.Item>
                <Divider />
                <div className="">
                  <ConfirmButton
                    label="Report Fault"
                    className="tw-w-full tw-mb-3 tw-mt-4 tw-bg-primary-500 tw-text-white hover:!tw-bg-primary-500 hover:!tw-text-white"
                    type="info"
                    onOk={() => form.submit()}
                    width={588}
                    content={
                      <>
                        <h2>Proceed to report the fault of this hardware?</h2>
                        <p>
                          Upon confirmation of this fault report, the condition of{' '}
                          <span className="tw-font-bold">{`${data?.serialCode}`}</span> will be changed to{' '}
                          <span className="tw-text-error-500">Faulty</span>.
                        </p>
                      </>
                    }
                  />
                  <SecondaryButton label={'Cancel'} onClick={handleCancel} className="tw-w-full" />
                </div>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default FaultyReportModal;
